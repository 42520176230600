// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/uprate-container.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/uprate-container.tsx");
  import.meta.hot.lastModified = "1712947911928.2556";
}
// REMIX HMR END

import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text, VStack, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import { AiOutlineLogin } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import UpratePrimaryButton from "./uprate-primary-button";
import UprateSecondaryIconButton from "./uprate-secondary-icon-button";
import { UprateLogo } from "./uprate-styles";
export default function UprateContainer({
  operator,
  loginOff,
  children
}) {
  _s();
  const {
    colorMode,
    toggleColorMode
  } = useColorMode();
  const hoverColor = useColorModeValue("purple.300", "purple.500");
  return <VStack gap={0} mb={8} align={"normal"} minH="100vh" bg={useColorModeValue("white", "gray.900")} css={{
    "&::-webkit-scrollbar": {
      width: "3px"
    },
    "&::-webkit-scrollbar-track": {
      width: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#7f7f7f",
      borderRadius: "24px"
    }
  }}>
      <Flex p="4" h="72px" w="full" justifyContent={"space-between"} borderBottomWidth={1} borderColor={useColorModeValue("purple.100", "purple.900")}>
        <Center>
          <UprateLogo />
        </Center>

        {!operator && !loginOff && <HStack gap={4}>
            <UprateSecondaryIconButton onClick={toggleColorMode} icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />} aria-label={"color mode"} />
            <UpratePrimaryButton leftIcon={<AiOutlineLogin />} mr={"4"} as={Link} to={"/login"}>
              Login
            </UpratePrimaryButton>
          </HStack>}

        {operator && <HStack gap={2}>
            <UprateSecondaryIconButton onClick={toggleColorMode} aria-label={"color mode"}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </UprateSecondaryIconButton>
            <Menu>
              <MenuButton py={2} transition="all 0.3s" _focus={{
            boxShadow: "none"
          }}>
                <HStack>
                  <Icon as={BsPerson} m={2} />
                  <Text display={{
                base: "none",
                sm: "flex"
              }} fontSize="sm">
                    {operator.email}
                  </Text>
                  <Box display={{
                base: "none",
                sm: "flex"
              }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem as={Link} _hover={{
              bg: hoverColor
            }} to="/hosts">
                  All Hosts
                </MenuItem>
                <MenuItem as={Link} _hover={{
              bg: hoverColor
            }} to="/logout">
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>}
      </Flex>
      {children}
    </VStack>;
}
_s(UprateContainer, "sQpCxFBJaDAaND0EsbeOmD6jmJo=", false, function () {
  return [useColorMode, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = UprateContainer;
var _c;
$RefreshReg$(_c, "UprateContainer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;